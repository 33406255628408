import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator, Avatar } from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"; // Import styles
import { useContext, useState } from "react";
import { DataContext } from "../DataContext"; // Import DataContext for WebSocket and chat messages

function LiveChat() {
    const { chatMessages, setChatMessages, websocketRef } = useContext(DataContext);
    const [message, setMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false); // Typing indicator

    // Handle sending messages
    const handleSendMessage = () => {
        if (message.trim() !== "" && websocketRef.current?.readyState === WebSocket.OPEN) {
            websocketRef.current.send(message);
            setMessage("");

            // Add sender's message
            setChatMessages((prevMessages) => [
                ...prevMessages,
                { text: message, sender: "You", direction: "outgoing" }
            ]);

            // Simulate a typing indicator for 1 second
            setIsTyping(true);
            setTimeout(() => setIsTyping(false), 1000);
        }
    };

    return (
        <div style={{ position: "relative", height: "calc(100vh - 7rem)", width: "100%" }}>
            <MainContainer>
                <ChatContainer>
                    <MessageList 
                        typingIndicator={isTyping ? <TypingIndicator content="Someone is typing..." /> : null}
                    >
                        {chatMessages.map((msg, index) => (
                            <>
                                <Message 
                                    key={index} 
                                    model={{
                                        message: msg.text,
                                        sender: msg.sender,
                                        direction: msg.sender === "You" ? "outgoing" : "incoming",
                                    }}                                
                                />
                                {msg.sender !== "You" ?<Avatar
                                    name="Emily"
                                    src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
                                />:""}
                            </>
                        ))}
                    </MessageList>
                    <MessageInput 
                        placeholder="Type a message..." 
                        value={message} 
                        onChange={setMessage} 
                        onSend={handleSendMessage} 
                        attachButton={false}
                    />
                </ChatContainer>
            </MainContainer>
        </div>
    );
}

export default LiveChat;
