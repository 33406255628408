import { Page, Grid, Card,Button,} from '@shopify/polaris';
import { useRef, useEffect, useCallback, useState, useContext } from "react";
import { useAppBridge } from '@shopify/app-bridge-react';
import LiveChat from './Components/LiveChat'; // Import LiveChat
import BroadcastModal from './Components/BroadcastModal';

import { DataContext } from "./DataContext";
import WHEPClient from './assets/js/WHEPClient';

const getIceServers = async () => {
    const turn_url = "https://video-project-test.pages.dev/get-ice-servers";
    const iceServersJSON = await fetch(turn_url);
    const iceServers = await iceServersJSON.json();
    return iceServers;
};


function Home() {
    const {setSelectedProducts} = useContext(DataContext);
    const videoRef = useRef(null);

    const shopify = useAppBridge();

    useEffect(() => {
        const initializeVideo = async () => {
            const { iceServers, videoUrl } = await getIceServers();
            if (videoRef.current) {
                WHEPClient(videoUrl, videoRef.current, iceServers);
            }
        };
        initializeVideo();
    }, []);

    const addProducts = useCallback(async () => {
        try {
            const selection = await shopify.resourcePicker({
                type: 'product',
                multiple: true
            });

            if (selection?.selection?.length) {
                setSelectedProducts(selection.selection);
                console.log("Selected Products:", selection.selection);
            } else {
                console.log("No products selected");
            }
        } catch (error) {
            console.error("Error selecting products:", error);
        }
    }, [shopify]);

    return (
        <Page 
            fullWidth 
            title="Studio" 
            secondaryActions={<Button onClick={addProducts} variant="primary">Add products</Button>}
            primaryAction={<Button onClick={() => shopify.modal.show('share-modal')} variant="primary">Broadcast</Button>}
        >           
            <Grid 
                columns={{xs: 1, sm: 4, md: 4, lg: 6, xl: 6}}
                areas={{
                xs: ['video', 'chat'],
                sm: [
                    'video video chat chat'
                ],
                md: ['video video chat chat'],
                lg: ['video video video video chat chat'],
                xl: ['video video video video chat chat'],
                }}
            >                
                <Grid.Cell area="video">                  
                    <Card padding={0}>                          
                        <video ref={videoRef} width="100%" style={{height:"calc(100vh - 5rem)",background:"#000",marginBottom:"-4px"}} id="remote-video" controls autoPlay muted></video>               
                    </Card>
                </Grid.Cell>

                {/* Updated Chat Component with WebSocket Fix */}
                <Grid.Cell area="chat">
                    <Card title="Live Chat">
                        <LiveChat /> {/* Use LiveChat component here */}
                    </Card>
                </Grid.Cell>
            </Grid>
            
            <BroadcastModal />
            
        </Page>
    );
}

export default Home;
