
import './App.css';
import {Frame} from '@shopify/polaris';
import { NavMenu } from '@shopify/app-bridge-react';
import { Routes, Route, Link} from "react-router-dom";
import Home from './Home';
import Live from './Live';
import Templates from './Templates';
import { DataProvider } from './DataContext';

function App() {
  return (
    <Frame >
      <DataProvider>
        <Routes>           
            <Route path="/" element={<Home />} />
            <Route path="/templates" element={<Templates />} />
            <Route path="/live" element={<Live />} />         
        </Routes>
        <NavMenu>
          <Link to="/" rel="home">Home</Link>
          <Link to="/templates">Templates</Link>
          <Link to="/live">Live</Link>
        </NavMenu>
      </DataProvider>
    </Frame>
  );
}

export default App;
