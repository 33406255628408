import React, { createContext, useState, useEffect, useRef } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [chatMessages, setChatMessages] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const websocketRef = useRef(null);

    useEffect(() => {
        if (!websocketRef.current) {
            websocketRef.current = new WebSocket('wss://test-calls.baya-dawdo8253.workers.dev/receive/websocket');

            websocketRef.current.onmessage = (event) => {
                let receivedMessage = event.data.trim();
                if (!receivedMessage.startsWith("[You]:")) {
                    const timestamp = new Date().toLocaleTimeString();
                    setChatMessages((prev) => [...prev, { text: receivedMessage, timestamp }]);
                }
            };

            websocketRef.current.onerror = (error) => console.error("WebSocket error:", error);

            websocketRef.current.onclose = () => {
                console.log("WebSocket closed. Reconnecting in 3 seconds...");
                setTimeout(() => {
                    websocketRef.current = new WebSocket('wss://test-calls.baya-dawdo8253.workers.dev/receive/websocket');
                }, 3000);
            };
        }

        return () => {
            websocketRef.current?.close();
        };
    }, []);

    return (
        <DataContext.Provider value={{
            chatMessages,
            setChatMessages,
            selectedProducts,
            setSelectedProducts,
            websocketRef
        }}>
            {children}
        </DataContext.Provider>
    );
}