import { Box, Text, BlockStack, Button, TextField } from '@shopify/polaris';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { QRCodeSVG } from 'qrcode.react';
import { useAppBridge, Modal, TitleBar } from '@shopify/app-bridge-react';


function BroadcastModal(){
    const shopify = useAppBridge();
    const shareUrl = "https://whep-player-test.pages.dev";
    
    // Copy to Clipboard Function
    const copyToClipboard = () => {
        try {
            navigator.clipboard.writeText(shareUrl)
                .then(() => {
                    shopify.toast.show('Copied to clipboard');
                })
                .catch(() => {
                    const textarea = document.createElement("textarea");
                    textarea.value = shareUrl;
                    textarea.style.position = "fixed";
                    textarea.style.top = "-9999px";
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand("copy");
                    document.body.removeChild(textarea);
                    
                    shopify.toast.show('Copied to clipboard');
                });
        } catch (error) {
            shopify.toast.show('Failed to copy text to clipboard');
        }
    };


    return (
        <Modal id="share-modal">               
            <Box padding="400">
                <BlockStack align="center" inlineAlign="center" gap="400">
                    <Box paddingBlock="400">    
                        <TextField
                            label="Copy and share this link"
                            type="url"
                            readOnly
                            value={shareUrl}
                            size="slim"
                            connectedRight={<Button icon={ClipboardIcon} onClick={copyToClipboard} accessibilityLabel="Copy url"/>}
                        />
                    </Box>
                    <Box paddingBlockEnd="400">
                        <Text as="p" variant="bodyMd">
                            Or scan the QR Code on your mobile phone
                        </Text>                        
                        <div style={{ marginTop: "10px" }} >
                            <QRCodeSVG width={200} height={200} value="https://whep-player-test.pages.dev" />
                        </div>
                    </Box>
                </BlockStack>
            </Box>
            
            <TitleBar title="Broadcast URL">
                <button onClick={() => shopify.modal.hide('share-modal')}>Close</button>
            </TitleBar>                
        </Modal>
    )
}

export default BroadcastModal;