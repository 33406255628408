

import {Page, Layout,Card} from '@shopify/polaris';
import {useRef,useEffect} from "react";

import WHEPClient from './assets/js/WHEPClient';
const video_url = "https://customer-j05sabze9a0uoknn.cloudflarestream.com/62199c48b8c88a5c0497978ee71bf8bd/webRTC/play";

const getIceServers = async () => {
    const turn_url = "https://video-project-test.pages.dev/get-ice-servers";
    const iceServersJSON = await fetch(turn_url);
    const iceServers = await iceServersJSON.json();
    return iceServers;
}

function Templates() {
    
    

    const videoRef = useRef(null);
    // useEffect(()=>{
    //     window.client = new WHEPClient(video_url, videoRef.current, getIceServers);
    // },[videoRef])

    useEffect(()=>{
        getIceServers()
        .then(response =>{
            const {iceServers,videoUrl} = response;
            WHEPClient(videoUrl,videoRef.current,iceServers);
        } );
        
    },[videoRef]);

    return (
        <Page title="Welcome to JsRates">           
            <Layout>
                <Layout.Section>
                    <Card>                        
                        <video ref={videoRef} width="100%" id="remote-video" controls autoPlay muted></video>
                    </Card>
                </Layout.Section>   

            </Layout>
        </Page>
    );
}

export default Templates;